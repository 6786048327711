import $ from "jquery";
import React, { useEffect } from "react";
import SectionGridLines from "../../../components/SectionGridLines";

const Footer = () => {
  //

  useEffect(() => {
    // Totop Button
    $(".totop a").on("click", function (e) {
      e.preventDefault();
      $("html, body").animate({ scrollTop: 0 }, "300");
    });

    const nav = document.querySelector(".header");
    const scrollUp = "top-up";
    let lastScroll = 800;

    window.addEventListener("scroll", () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll <= 800) {
        nav.classList.remove(scrollUp);
        $(".totop").removeClass("show");
        return;
      }

      if (currentScroll > lastScroll) {
        // down
        nav.classList.add(scrollUp);
        $(".totop").addClass("show");
      } else if (currentScroll < lastScroll) {
        // up
        nav.classList.remove(scrollUp);
        $(".totop").removeClass("show");
      }
      lastScroll = currentScroll;
    });
  }, []);
  return (
    <>
      <footer className="footer bg-dark-200 box_padding">
        <div className="footer_inner bg-black">
          <div className="container">
            <div className="row align-items-end">
              <div className="col-lg-4 col-md-2 col-sm-2">
                <div className="section-header">
                 
                </div>
              </div>
              <div className="col-lg-4 col-md-5 col-sm-5">
                <div className="communication">
                  <div className="info_body">
                    <h4  className="mb-1">Vefsíða</h4>
                   
                      <a
                        href="http://www.raftvistur.is"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                      raftvistur.is
                      </a>
                   <br></br>
                      <a
                        href="http://www.raf2.is"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                      raf2.is
                      </a>
                   
                  </div>
                  <div className="info_body">
                    <h4>Tölvupóstur</h4>
                   
                      <a href="mailto:patur@raftvistur.is">
                        raftvistur@raftvistur.is
                      </a>
                    
                  </div>
                  <div className="info_body ">
                    <h4 className="mb-1">Símanúmer</h4>
                   
                      <a href="tel:+3548984659">+354 898 4659</a>
                
                  </div>
                  <div className="info_body">
                    <h4  className="mb-1">Heimilisfang</h4>
                   
                      <a
                        aria-label="contact redirect"
                        alt="link to contact"
                        href="https://www.raftvistur.is/contact"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Raftvistur ehf , Tónahvarf 6o<br></br>203 Kópavogur
                      </a>
                   
                  </div>
                  <div className="info_body">
                    <h4  className="mb-1">Kt.</h4>
                    501197-2709
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-5 col-sm-5">
                <div className="footer_elements d-flex align-items-center justify-content-end">
                  <div className="footer_elements_inner">
                    <div
                      className="footer_logo"
                      style={{ paddingBottom: "24px" }}
                    >
                      <a href="/" className="light_logo">
                        <img
                          src="images/logo-light.svg"
                          alt="Raftvistur logo"
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                      </a>
                    </div>
                    <div className="footer_social">
                      <ul className="social_list">
                        <li>
                          <a
                            aria-label="social links"
                            href="https://www.facebook.com/Raftvisturehf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="bi bi-facebook"></i>
                          </a>
                        </li>

                        <li>
                          <a
                            aria-label="social links"
                            href="https://www.instagram.com/raftvistur"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="bi bi-instagram"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="terms_condition">
                      <ul>
                        <li>Skilmálar</li>
                        <li>Aðstæður</li>
                        <li>Stefna</li>
                      </ul>
                    </div>
                    <div className="copyright">
                      <p>
                        Raftvistur ehf. 2025©. Allur réttur áskilinn.
                        kt.501197-2709
                      </p>
                      <p className="footerskyfaze"> {`{ Developed by `}
    <a href="https://skyfaze.com" target="_blank" rel="noopener noreferrer">SKY FAZE</a>
    {` }`}
  </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <SectionGridLines />
        </div>
      </footer>

      <div className="totop">
        <a aria-label="up button" alt="up button" href="#">
          UP
        </a>
      </div>
    </>
  );
};

export default Footer;
