import React, { useState, useEffect, useRef } from 'react';
import PageBanner03 from '../../containers/PageBanner03';
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore';
import { db } from '../../firebase.config';
import Lightbox from 'yet-another-react-lightbox';
import ProjectGallery56 from '../../containers/ProjectContainers/ProjectGallery56';
import 'yet-another-react-lightbox/styles.css';

const Works = () => {
  const [gallery, setGallery] = useState([]);
  const [visibleCount, setVisibleCount] = useState(10);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const sentinelRef = useRef(null);

  useEffect(() => {
    const q = query(collection(db, 'mediaGallery'), orderBy('orderIndex', 'asc'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const items = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setGallery(items);
    });
    return () => unsubscribe();
  }, []);

  // Infinite scrolling observer
  useEffect(() => {
    if (!sentinelRef.current) return;
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setVisibleCount(prev => Math.min(prev + 10, gallery.length));
      }
    }, { threshold: 1.0 });
    observer.observe(sentinelRef.current);
    return () => observer.disconnect();
  }, [gallery]);

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  // Helper function to check if a URL points to a video file
  const isVideoUrl = (url) => {
    try {
      const decodedUrl = decodeURIComponent(url);
      return /\.(mp4|webm|ogg)(\?.*)?$/i.test(decodedUrl);
    } catch (error) {
      console.error('Error decoding URL:', error);
      return false;
    }
  };

  return (
    <>
      <PageBanner03 activePage="Verkefni" />
      <ProjectGallery56 text="Sjá Meira" className="btn gray" />
      <div className="project-details">
        <figure>
          <ul className="responsive-gallery-grid">
            {gallery.slice(0, visibleCount).map((media, index) => (
              <li
                key={media.id}
                className="gallery-item-custom"
                onClick={() => openLightbox(index)}
                style={{ cursor: 'pointer' }}
              >
                <figure>
                  {isVideoUrl(media.mediaUrl) ? (
                    <video
                      src={media.mediaUrl}
                      muted
                      loop
                      playsInline
                      preload="auto"
                      style={{ width: '100%', height: 'auto' }}
                      controls
                    />
                  ) : (
                    <img src={media.mediaUrl} alt="media" loading="lazy" />
                  )}
                </figure>
              </li>
            ))}
          </ul>
        </figure>
        {/* Sentinel element for infinite scrolling */}
        <div ref={sentinelRef} style={{ height: '1px' }} />
      </div>

      {isOpen && gallery.length > 0 && (
        <Lightbox
          open={isOpen}
          close={() => setIsOpen(false)}
          slides={gallery.map(media => ({ src: media.mediaUrl }))}
          index={photoIndex}
          carousel={{ finite: true, imageFit: 'contain' }}
        />
      )}
    </>
  );
};

export default Works;
