import React from 'react';
import Paragraph from './Paragraph';
import SubHeading from './SubHeading';

const FormText = () => {
    return (
        <div className="col-lg-5">
            <div className="section-header">
                <SubHeading title="Vertu í Sambandi 
Raftvistur ehf.
"></SubHeading>
                <Paragraph text="Ertu með fyrirspurn eða verkefni í huga sem snýr að okkar sérhæfingu? Skildu eftir skilaboð og við komum til móts við þínar þarfir eða beinum þér á rétta braut.
"></Paragraph>
            </div>
        </div>
    );
};

export default FormText;