import React from 'react';

import PageHeader from '../../components/PageHeder';
import Paragraph from '../../components/Paragraph';
import TeamCard from '../../components/TeamElements/TeamCard';
import 'font-awesome/css/font-awesome.min.css';

const TeamContainer = () => {
    const TeamData = [
        {
            id: '1',
            img: 'images/team/1.png',
            name: 'Pétur Hrólfsson',
            designation: 'Framkvæmdarstjóri',
            email: 'Petur@raftvistur.is',
            phone: '+3548984659'  
        },
        {
            id: '2',
            img: 'images/team/2.png',
            name: 'Rafn Pálsson',
            designation: 'Rafvirkjameistari',
            email: 'Rabbi@raftvistur.is',
            phone: '+3548921151'  
        },
        {
            id: '3',
            img: 'images/team/3.png',
            name: 'Magnús Már Pétursson',
            designation: 'Rafvirki',
            email: 'Magnus@raftvistur.is',
            phone: '+3547773320'  
        },
        {
            id: '4',
            img: 'images/team/3.5.png',
            name: 'Sigurveig Jóhannesdóttir',
            designation: 'Bókhald',
            email: 'Sigurveig@raftvistur.is',
            phone: '+3548964659'  
        },
        {
            id: '5',
            img: 'images/team/4.png',
            name: 'Hrólfur Smári Pétursson',
            designation: 'Sérverkefni, Rafvirkjanemi',
            email: 'Hrolfur@raftvistur.is',
            phone: '+3547702908'  
        },
        {
            id: '6',
            img: 'images/team/5.png',
            name: 'Arnar Ingi Thors',
            designation: 'Rafvirki',
            email: 'Arnar@raftvistur.is',
            phone: '+3548993105'  
        },
        {
            id: '7',
            img: 'images/team/6.png',
            name: 'Brynjar Snær Grétarsson',
            designation: 'Rafvirkjanemi',
            email: 'Brynjar@raftvistur.is',
            phone: '+3547758582'  
        },
        {
            id: '8',
            img: 'images/team/6.png',
            name: 'Hlynur Snær Wiium',
            designation: 'Rafvirkjanemi',
            email: 'Hlynur@raftvistur.is',
            phone: '+3547764477,'  
        },
      


      
    ]
    return (
        <section className="team in-team">
            <div className="container-sm">
                <div className="section-header medium">
                <PageHeader className='heading' title="Kynntu þér liðið okkar" />
<div className="section-desc row align-items-center justify-content-center">
    <div className="col-lg-5">
        <Paragraph text="Raftvistur ehf. var stofnað 1997 og sérhæfir sig í lausnum á háspennubúnaði og hefur sótt sér umboð hjá Südkabel í Þýskalandi." />
    </div>
    <div className="col-lg-7">
        <Paragraph text="Með eljusemi og lausnamiðun í sérhæfðum verkefnum hefur fyrirtækið öðlast sérstöðu í háspennuverkefnum á Íslandi. Við bjóðum upp á alhliða þjónustu, allt frá ráðgjöf að uppsetningu, í há- og lágspennu í iðnaðar- og húsarafmagni og töflusmíði." />
    </div>

                    </div>
                </div>

                <div className="team_inner">
                    <div className="row">
                        {
                            TeamData.map(data =>
                                <TeamCard
                                    key={data.id}
                                    data={data}
                                />)
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TeamContainer;