import React from "react";

const Articles = ({ aboutClass }) => {
  return (
    <>
      <div className={`about-area ${aboutClass}`}>
        <div className="blur-bg-theme"></div>
        <div className="container">
          <div className="row">
            <div className="about-style-one">
              <div className="pdf" style={{ marginTop: "35px" }}>
                <h2 className="sub-title">
                  {window.innerWidth < 768 ? (
                    <a href="/calendar2024.pdf" download>
                      〜 Download the Calendar 2025 PDF
                    </a>
                  ) : (
                    "Calendar 2025"
                  )}
                </h2>
                <br></br>
                {window.innerWidth >= 768 && (
                  <embed
                    src="/calendar2025.pdf#view=FitH"
                    style={{
                      width: "100%",
                      height: "900px",
                      borderRadius: "10px",
                    }}
                    type="application/pdf"
                  />
                )}
              </div>

             

               
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Articles;
