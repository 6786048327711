import React, { useState } from 'react';
import emailjs from '@emailjs/browser';

const Form = () => {
  const [message, setMessage] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();
  
    const name = e.target.name.value;
    const phone = e.target.phone.value;
    const email = e.target.email.value;
    const msg = e.target.message.value;

    if (!name || !phone || !email || !msg) {
      setMessage('All fields are required.');
      return;
    }

    emailjs.sendForm('service_ligiurc', 'template_0co0x4m', e.target, 'WZaB9Vi8ZGjogdl4M')
      .then((result) => {
        setMessage('Message sent successfully!');
        console.log(result.text);
      }, (error) => {
        setMessage('Oops, something went wrong.');
        console.log(error.text);
      });
  };

  return (
    <div className="home_contact">
      <form onSubmit={sendEmail}>
        <input className="form-control form-control-lg" name="name" id="name" type="text" placeholder="Nafn" required aria-label=".form-control-lg dæmi" />
        <input className="form-control form-control-lg" name="phone" id="phone" type="tel" placeholder="Sími" aria-label=".form-control-lg dæmi" />
        <input className="form-control form-control-lg" name="email" id="email" type="email" placeholder="Netfang*" required aria-label=".form-control-lg dæmi" />
        <textarea className="form-control pt-4" name="message" id="message" placeholder="Skilaboð til okkar" rows="3"></textarea>
        <div className="btn_group">
          <button aria-label="toggler homepage" type="submit" className="btn olive">Senda Póst</button>
        </div>
      </form>
      {message && <p className={message.includes('successfully') ? 'message-success' : 'message-error'}>{message}</p>}
    
    </div>
  );
};

export default Form;