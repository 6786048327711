import React from 'react';

const AsideInfo = () => {
    return (
        <div className="aside_info_wrapper">
            <button aria-label="toggler homepage" className="aside_close"><i className="bi bi-x-lg"></i></button>
            <div className="aside_logo">
                <a href="/" className="light_logo"><img src="images/logo-light.svg" alt="logo" /></a>
                <a href="/" className="dark_logo"><img src="images/logo-dark.svg" alt="logo" /></a>
            </div >
            <div className="aside_info_inner">
                <p>Sérfræðingar í háspennu, iðnaðar- og húsarafmagni og skápasmíði.</p>

                <div className="aside_info_inner_box">
                <h5>Hafðu samband</h5>
<p><a href="tel:+3548984659">+354 898 4659</a></p>
<p><a href="https://www.raftvistur.is/contact" aria-label='contact redirect' alt="link to contact"  target="_blank" rel="noopener noreferrer">Raftvistur ehf , Tónahvarf 6o<br></br>203 Kópavogur</a></p>
<p><a href="mailto:petur@raftvistur.is">raftvistur@raftvistur.is</a></p>

                  
                </div>
                <div className="social_sites">
                    <ul className="d-flex align-items-center justify-content-center">
                    <li><a aria-label='social links'  href="https://www.facebook.com/Raftvisturehf" target="_blank" rel="noopener noreferrer"><i className="bi bi-facebook"></i></a></li>

<li><a aria-label='social links'  href="https://www.instagram.com/raftvistur" target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram"></i></a></li>

                    </ul>
                </div>
            </div>
        </div >

    );
};

export default AsideInfo;