import { initializeApp, getApps, getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCy0Tqe24nlzSl-3GJ53UY_wHCR5Cu7E3Y",
  authDomain: "raftvist-dashboard.firebaseapp.com",
  projectId: "raftvist-dashboard",
  storageBucket: "raftvist-dashboard.firebasestorage.app",
  messagingSenderId: "1083896582939",
  appId: "1:1083896582939:web:f844714b1313a613ddb9be"
};

let firebaseApp;
if (!getApps().length) {
  firebaseApp = initializeApp(firebaseConfig);
} else {
  firebaseApp = getApp();
}

const db = getFirestore(firebaseApp);

export { db };
